/* eslint-disable no-unused-expressions */
import apiSlice from '../../app/services/apiSlice';
import { BUSINESS_LANGUAGES, SOCIAL } from '../../common/constants';
import prepareURL from '../../common/prepareURL';
import { addBusiness, deleteBusiness, deleteBusinessAndUpdateActive, setBusinessState } from './businessSlice';

const basePath = '/business';

const businessApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBusinessByRole: builder.query({
			query: () => prepareURL(`${basePath}/permissions`),
			transformResponse: (response) => {
				const { businesses } = response;
				return businesses || [];
			},
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;

					dispatch(setBusinessState(data));
				} catch (err) {
					// `onError` side-effect
				}
			},
		}),
		businesses: builder.query({
			query: (args) => prepareURL(basePath, args),

			providesTags: ['Businesses'],
		}),
		getBusiness: builder.query({
			query: (args) => prepareURL(`${basePath}/{id}`, args),
			transformResponse: (response) => {
				const business = response.item;
				const tranformedBusiness = {
					title: [{ lng: business.default_locale, text: business.title }],
					logo: business.logo,
					vat: business.vat,
					validEuVat: business.invalid_eu_vat,
				};

				if (business?.contact_info) {
					const web = business.contact_info.find((obj) => obj.type === 'web');
					if (web) {
						tranformedBusiness.url = web.val;
					}

					const socials = business.contact_info.filter((obj) => obj.type !== 'web');

					socials.length > 0 ? (tranformedBusiness.social = socials) : (tranformedBusiness.social = [{ type: SOCIAL[0], val: '' }]);
				}

				tranformedBusiness.tncs = [];
				if (business?.tncs) {
					tranformedBusiness.tncs.push({ lng: business.default_locale, text: business.tncs });
				}

				BUSINESS_LANGUAGES.forEach(({ code }) => {
					const translationKeys = Object.keys(business?.translations || []);

					if (translationKeys.includes(code)) {
						tranformedBusiness.title.push({ lng: code, text: business.translations[code].title || '' });
						tranformedBusiness.tncs.push({ lng: code, text: business.translations[code].tncs || '' });
					} else {
						business.default_locale !== code && tranformedBusiness.title.push({ lng: code, text: '' });
						(!business?.tncs || business.default_locale !== code) && tranformedBusiness.tncs.push({ lng: code, text: '' });
					}
				});

				return tranformedBusiness;
			},
			providesTags: ['Businesses'],
		}),
		createBusiness: builder.mutation({
			query: (inputs) => ({
				url: basePath,
				method: 'POST',
				body: inputs,
			}),
			invalidatesTags: ['Businesses'],
			refetchOn: 'fulfilled',
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(addBusiness(data.business));
				} catch (err) {
					// `onError` side-effect
				}
			},
		}),
		editBusiness: builder.mutation({
			query: ({ args, inputs }) => ({
				url: prepareURL(`${basePath}/{id}`, args),
				method: 'PUT',
				body: inputs,
			}),

			invalidatesTags: ['Businesses'],
			refetchOn: 'fulfilled',
		}),
		deleteBusiness: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/{id}`, args),
				method: 'DELETE',
			}),
			invalidatesTags: ['Businesses'],
			refetchOn: 'fulfilled',
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(deleteBusinessAndUpdateActive(data.business));
				} catch (err) {
					// `onError` side-effect
				}
			},
		}),
		store: builder.query({
			query: (args) => {
				prepareURL(`${basePath}/{bid}/store/{sid}`, args);
			},
			transformResponse: (response) => {
				const { store } = response;
				const preferenceOptions = store?.reservation_prefs?.map((pref) => ({
					value: pref._id,
					label: pref.title,
					incompatible: pref.incompatible,
				}));
				return preferenceOptions;
			},
		}),
	}),
});

export const {
	useGetBusinessByRoleQuery,
	useBusinessesQuery,
	useGetBusinessQuery,
	useCreateBusinessMutation,
	useEditBusinessMutation,
	useDeleteBusinessMutation,
	useStoreQuery,
} = businessApiSlice;
