import * as Yup from 'yup';

export enum BlockingAreas {
	TABOL = 'Tabol',
	WALK_IN = 'Walk in',
	WIDGET = 'Widget',
}

export const validationSchema = Yup.object().shape({
	date: Yup.date().required('Date is required'),
	startTime: Yup.string().required('Start time is required'),
	endTime: Yup.string().required('End time is required'),
	tabol: Yup.boolean(),
	walk_in: Yup.boolean(),
	widget: Yup.boolean(),
});

export type BlockingHoursItem = {
	id: string | number;
	date: Date;
	startTime: string;
	endTime: string;
	tabol: boolean;
	walk_in: boolean;
	widget: boolean;
};

export type BlockingHoursItemFields = Omit<BlockingHoursItem, 'id'>;

export const initialValues: BlockingHoursItemFields = {
	date: new Date(),
	startTime: '',
	endTime: '',
	tabol: false,
	walk_in: false,
	widget: false,
};

export type BlockingHoursResponse = {
	date: string;
	time: [string, string]; // Tuple for start and end times
	walk_in: boolean;
	tabol: boolean;
	widget: boolean;
};

export type ApiResponse = {
	item?: {
		online_booking_settings?: {
			blocking?: BlockingHoursResponse[];
		};
	};
};
