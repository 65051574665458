import clsx from 'clsx';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { KTSVG } from '../../../../../_metronic/helpers';
import { BUSINESS_LANGUAGES } from '../../../../../common/constants';
import LanguageSelector from '../../../../../components/languageSelector/LanguageSelector';
import useConfirmationModal from '../../../../../components/modal/useConfirmationModal';
import { optionsCancellationPolicy, socialList } from './StoreOptions';

const LocationSocialTextFields = (props) => {
	const { register, control, watch, trigger, errors, handleVatCheckboxChange, setValue, disableVat, setDisableVat } = props;
	const [confirmAction, confirmationModal] = useConfirmationModal();

	const prepayment_policy = watch('prepayment_policy');
	const cancellationPolicy = watch('cancelation_policy');
	const notes = watch('notes');
	const [prepaymentLang, setPrepaymentLang] = useState(BUSINESS_LANGUAGES[0].code);
	const [cancellationLang, setCancellationLang] = useState(BUSINESS_LANGUAGES[0].code);
	const [notesLang, setNotesLang] = useState(BUSINESS_LANGUAGES[0].code);
	const translations = BUSINESS_LANGUAGES.reduce((acc, lang) => {
		acc[lang?.code] = {
			prepayment_policy: watch(`translations[${lang?.code}].prepayment_policy`),
			cancelation_policy: watch(`translations[${lang?.code}].cancelation_policy`),
			notes: watch(`translations[${lang?.code}].notes`),
		};
		return acc;
	}, {});
	const handlePrepaymentLanguageChange = (language) => setPrepaymentLang(language);
	const handleCancellationLanguageChange = (language) => setCancellationLang(language);
	const handleNotesLanguageChange = (language) => setNotesLang(language);

	// Social
	const social = watch('contact_info.social');
	const removeSocial = (index) => {
		delete social[index];
	};
	const appendSocial = () => {
		social.push({ type: 'web', val: '' });
		trigger('contact_info.social');
	};

	// VAT
	const isVatVisible = false; // TOD0 - Placeholder to hide VAT
	const handleVatCheckbox = (event) => {
		setDisableVat(!disableVat);
		setValue('vatNumber', '', disableVat === true);
		const isVatDisabled = event.target.checked;
		handleVatCheckboxChange(isVatDisabled);
	};
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.SOCIAL" />
					</div>
				</div>
			</div>

			{social &&
				social.map((socialField, index) => (
					<div className="row pb-5 g-5" key={index}>
						<div className="col-12 col-sm-3">
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								{...register(`contact_info.social[${index}].type`)}
								defaultValue={socialField.type ?? socialList[0].value}
							>
								{socialList.map((socialOption) => (
									<option key={socialOption.type} value={socialOption.value} defaultValue={socialOption.type}>
										{socialOption.type}
									</option>
								))}
							</select>
						</div>
						<div className="col-12 col-sm-7">
							<input
								type="text"
								name={`social[${index}].val`}
								{...register(`contact_info.social[${index}].val`)}
								className={clsx('form-control form-control-solid', {
									'is-invalid': errors?.contact_info?.social && errors?.contact_info.social[index]?.val,
								})}
							/>
							{errors?.contact_info?.social && errors?.contact_info?.social[index] ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors?.contact_info?.social[index].val.message}</span>
									</div>
								</div>
							) : null}
						</div>
						<div className="col-12 col-sm-2 d-flex align-items-start">
							<button
								type="button"
								onClick={() => confirmAction(() => removeSocial(index))}
								className="btn btn-light-danger btn-color-danger w-100 d-flex justify-content-center align-items-center"
							>
								<KTSVG className="svg-icon-1" path="/media/icons/duotune/general/gen027.svg" />
								<div className="fs-7">
									<FormattedMessage id="GENERAL.DELETE" />
								</div>
							</button>
						</div>
					</div>
				))}
			<div className="row">
				<div className="col-12 col-sm-3">
					<button type="button" onClick={() => appendSocial()} className="generic-button fs-6 w-100 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.ADD_SOCIAL" />
					</button>
				</div>
			</div>
			<Controller
				name="use_business_social"
				control={control}
				render={({ field: { value, onChange, ref, name } }) => (
					<div className="row pt-3">
						<div className="col-12">
							<div className="form-check form-check-custom form-check-solid pe-3 pt-md-0 pt-3">
								<input
									ref={ref}
									name={name}
									className="form-check-input"
									type="checkbox"
									id={'business-social-checkbox'}
									aria-labelledby={`business-social-checkbox`}
									onChange={onChange}
									checked={value}
									value={value}
								/>
								<label className="form-check-label fs-7" htmlFor="business-social">
									<FormattedMessage id="STORE.MODAL.DETAILS.USE_SOCIAL" />
								</label>
							</div>
							{errors?.use_business_social && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.use_business_social.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			/>

			{isVatVisible ? (
				<>
					<div className="row">
						<div className="col-12">
							<div className="separator my-5" />
						</div>
					</div>

					<div className="row pb-5">
						<div className="col-12">
							<div className="mb-0 fs-2 fw-bold">
								<FormattedMessage id="STORE.MODAL.DETAILS.VAT" />
							</div>
						</div>
					</div>

					<div className="row pb-5">
						<div className="col-12">
							<div className="input-group">
								<label htmlFor="location-vat" className="form-label pb-3 mb-0 fs-7 fw-bold">
									<FormattedMessage id="GENERAL.VAT.NUMBER" />
									<sup>*</sup>
								</label>
								<input
									type="text"
									className="form-control p-4"
									id="location-vat"
									name="location-vat"
									aria-describedby="businessTitle"
									placeholder="EL"
									{...register('vatNumber')}
									disabled={disableVat === true}
								/>
								{errors?.vatNumber && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.vatNumber.message}</span>
										</div>
									</div>
								)}
							</div>
							<p className="mb-0 pt-3">
								<FormattedMessage id="GENERAL.VAT.NUMBER.MSG" />
								<a href="https://en.wikipedia.org/wiki/VAT_identification_number" target="_blank" rel="noopener noreferrer">
									<FormattedMessage id="GENERAL.VAT.NUMBER.COUNTRIES.LIST" />
								</a>
							</p>
						</div>
					</div>

					<div className="row pb-5">
						<div className="col-12">
							<div className="form-check form-check-custom form-check-solid pe-3 pt-md-0 pt-3">
								<input
									className="form-check-input"
									type="checkbox"
									value=""
									id="vat-option"
									name="vat-option"
									onChange={handleVatCheckbox}
								/>
								<label className="form-check-label fs-7" htmlFor="vat-option">
									<FormattedMessage id="STORE.MODAL.DETAILS.NOT_HAVE_VAT" />
								</label>
							</div>
						</div>
					</div>
				</>
			) : null}

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.PREPAYMENT" />
					</div>
				</div>
			</div>

			<div className="p-3 generic-border">
				<LanguageSelector language={prepaymentLang} onLanguageChange={handlePrepaymentLanguageChange} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="prepayment" className="form-label pb-3 mb-0 fs-7 fw-bold" />
						<textarea
							id="prepayment"
							name="prepayment"
							className="full-width-textarea"
							rows="4"
							cols="50"
							{...register(`translations[${prepaymentLang}.prepayment_policy` ?? 'prepayment_policy')}
							defaultValue={
								translations[prepaymentLang]?.prepayment_policy ? translations[prepaymentLang]?.prepayment_policy : prepayment_policy
							}
							value={translations[prepaymentLang]?.prepayment_policy !== '' ? translations[prepaymentLang]?.prepayment_policy : ''}
						/>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.CANCELLATION_POLICY" />
					</div>
				</div>
				<Controller
					name="cancel_max_time_hrs"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<select className="form-select form-select-solid" aria-label="Select example" {...register('cancel_max_time_hrs')}>
								{optionsCancellationPolicy.map((policy, index) => (
									<option value={policy.value} key={index}>
										{policy.label}
									</option>
								))}
							</select>
						</div>
					)}
				/>
				<div className="col-12">
					<p className="mb-0 secondary-text fs-7 pt-2">
						<FormattedMessage id="STORE.MODAL.DETAILS.CANCELLATION_POLICY_TXT" />
					</p>
				</div>
			</div>

			<div className="p-3 generic-border">
				<LanguageSelector language={cancellationLang} onLanguageChange={handleCancellationLanguageChange} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="cancellation" className="form-label pb-3 mb-0 fs-7 fw-bold" />
						<textarea
							id="cancellation"
							name="cancellation"
							className="full-width-textarea"
							rows="4"
							cols="50"
							{...register(`translations[${cancellationLang}.cancelation_policy` ?? 'cancelation_policy')}
							defaultValue={
								translations[cancellationLang]?.cancelation_policy
									? translations[cancellationLang]?.cancelation_policy
									: cancellationPolicy
							}
							value={
								translations[cancellationLang]?.cancelation_policy !== '' ? translations[cancellationLang]?.cancelation_policy : ''
							}
						/>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.NOTES" />
					</div>
				</div>
			</div>

			<div className="p-3 generic-border">
				<LanguageSelector language={notesLang} onLanguageChange={handleNotesLanguageChange} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="notes" className="form-label pb-3 mb-0 fs-7 fw-bold" />
						<textarea
							id="notes"
							name="notes"
							className="full-width-textarea"
							rows="4"
							cols="50"
							{...register(`translations[${notesLang}.notes` ?? 'notes')}
							defaultValue={translations[notesLang]?.notes ? translations[notesLang]?.notes : notes}
							value={translations[notesLang]?.notes !== '' ? translations[notesLang]?.notes : ''}
						/>
					</div>
				</div>
			</div>
			{confirmationModal}
		</>
	);
};

export default LocationSocialTextFields;
