import apiSlice from '../../app/services/apiSlice';
import prepareURL from '../../common/prepareURL';
import { getSessionStorage } from '../../common/storage';
import { setAvailabilityState } from './availabilitySlice';

const basePath = '/reservations';

const reservationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		reservation: builder.query({
			query: (args) => prepareURL(basePath, args),
			providesTags: ['Reservation'],
		}),
		getReservation: builder.query({
			query: (args) => ({
				method: 'GET',
				url: prepareURL(`${basePath}/${args.path.id}`, args),
			}),
			providesTags: ['ReservationDetails'],
		}),
		getStoreAvailability: builder.query({
			query: (args) => {
				const sid = JSON.parse(getSessionStorage('act_str'))?._id;
				return prepareURL(
					basePath +
						`/store/${args.path.id ?? sid}/availability?people=${args.people ?? 2}${
							args.reservationId ? `&reservation_id=${args?.reservationId}&` : '&'
						}date=${args.date ?? new Date()}&source=${args.source ?? 'walk-in'}${args.time ? `&time=${args.time}&` : '&'}duration_mins=${
							args.duration ?? 120
						}`,
					args
				);
			},
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setAvailabilityState(data));
				} catch (err) {
					// `onError`
				}
			},
		}),
		createReservation: builder.mutation({
			query: (args) => ({
				url: basePath,
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['Reservation'],
		}),
		editReservation: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/${args.path.id}`, args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['Reservation', 'ReservationDetails'],
		}),
		deleteReservation: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/${args.path.id}`, args),
				method: 'DELETE',
			}),
			invalidatesTags: ['Reservation', 'ReservationDetails'],
		}),
		refundPayment: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/${args.path.id}/refund-payments`, args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['Reservation', 'ReservationDetails'],
		}),
	}),
});

export const {
	useReservationQuery,
	useGetReservationQuery,
	useGetStoreAvailabilityQuery,
	useCreateReservationMutation,
	useEditReservationMutation,
	useDeleteReservationMutation,
	useRefundPaymentMutation,
} = reservationApiSlice;
