import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getSessionStorage, removeLocalStorage, setSessionStorage } from '../../common/storage';

const initialState = {
	businesses: [],
	activeBusiness: JSON.parse(getSessionStorage('act_bsn')) || {},
	activeStore: JSON.parse(getSessionStorage('act_str')) || {},
};

const businessSlice = createSlice({
	name: 'business',
	initialState,
	reducers: {
		setBusinessState: (state, action) => {
			const businesses = action.payload;
			let actBsn = state.activeBusiness;
			let actStr = state.activeStore;

			let savedBsn = JSON.parse(getSessionStorage('act_bsn'));
			let savedStr = JSON.parse(getSessionStorage('act_str'));

			if (savedBsn && savedStr) {
				if (savedBsn && businesses.find((b) => b._id === savedBsn._id)) {
					actBsn = savedBsn;
				}
				if (savedStr && businesses.find((b) => b._id === savedStr._id)) {
					actStr = savedStr;
				}
			} else {
				if (businesses[0]) {
					actBsn = { _id: businesses[0]._id, title: businesses[0].title, logo: businesses[0].logo };
				}
				if (businesses[0] && businesses[0].stores && businesses[0].stores[0]) {
					actStr = { _id: businesses[0].stores[0]._id, title: businesses[0].stores[0].title };
				}
			}

			state.businesses = businesses;
			state.activeBusiness = actBsn;
			state.activeStore = actStr;
			setSessionStorage('act_bsn', JSON.stringify(actBsn));
			setSessionStorage('act_str', JSON.stringify(actStr));
		},
		addBusiness: (state, action) => {
			const business = action.payload;

			state.businesses.push(business);
		},
		deleteBusiness: (state, action) => {
			const businessId = action.payload.id;
			state.businesses = state.businesses.filter((b) => b._id !== businessId);
		},
		setActiveBusiness: (state, action) => {
			const { business } = action.payload;

			if (state.activeBusiness._id !== business._id) {
				state.activeBusiness = business;
				setSessionStorage('act_bsn', JSON.stringify(business));
			}
		},
		setActiveBusinessStore: (state, action) => {
			const { store, business } = action.payload;

			if (state.activeBusiness._id !== business._id) {
				state.activeBusiness = business;
				setSessionStorage('act_bsn', JSON.stringify(business));
			}
			if (store != null) {
				state.activeStore = store;
				setSessionStorage('act_str', JSON.stringify(store));
			} else {
				removeLocalStorage('act_str');
			}
		},
		setActiveStore: (state, action) => {
			if (action.payload == null) {
				removeLocalStorage('act_str');
				state.activeStore = {};
				return;
			}
			const { _id, title } = action.payload;

			state.activeStore = { _id, title };
			setSessionStorage('act_str', JSON.stringify({ _id, title }));
		},
	},
});

export const { setBusinessState, setActiveStore, setActiveBusinessStore, setActiveBusiness, addBusiness, deleteBusiness } = businessSlice.actions;

export const selectBusinesses = ({ business }) => business.businesses;
export const selectActiveBusiness = ({ business }) => business.activeBusiness;
export const selectActiveStore = ({ business }) => business.activeStore;

export const selectMemoBusinessStores = (bid) =>
	createSelector([selectBusinesses], (businesses) => {
		const selectedBusiness = businesses.find((b) => b._id === bid);

		return selectedBusiness?.stores || [];
	});

// Add this new thunk action creator
export const deleteBusinessAndUpdateActive = (business) => (dispatch, getState) => {
	dispatch(deleteBusiness({ id: business._id }));

	const state = getState();
	const businesses = state.business.businesses;

	if (business.id === state.business.activeBusiness._id && businesses.length > 0) {
		const firstBusiness = businesses[0];
		const firstStore = firstBusiness.stores[0];
		dispatch(
			setActiveBusinessStore({
				business: firstBusiness,
				store: firstStore,
			})
		);
	}
};

export default businessSlice.reducer;
