import jwtDecode from 'jwt-decode';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { isEmptyObj } from 'src/common/misc';
import { getLocalStorage, removeLocalStorage } from 'src/common/storage';

import { AuthLayout } from './AuthLayout';
import { ForgotPassword } from './components/ForgotPassword';
import Login from './components/Login';
import { Registration } from './components/Registration';

const AuthPage = () => {
	const location = useLocation();

	const token = getLocalStorage('accessToken');
	const jwt = token ? jwtDecode(token) : {};
	if (jwt && !isEmptyObj(jwt)) {
		return <Navigate to="/dashboard" replace />;
	}
	return (
		<Routes>
			<Route element={<AuthLayout />}>
				<Route path="login" element={<Login />} />
				<Route path="registration" element={<Registration />} />
				{/* <Route path="forgot-password" element={<ForgotPassword />} /> */}
				<Route index element={<Login />} />
			</Route>
		</Routes>
	);
};

export { AuthPage };
