/* eslint-disable prefer-template */
import apiSlice, { API_ROOT } from '../app/services/apiSlice';

const basePath = '/files';

const fileApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		files: builder.query({
			query: (id) => {
				let url = API_ROOT;
				if (id) {
					url += `/files/${id}`;
				} else {
					url += '/files';
				}
				return url;
			},
			providesTags: ['Files'],
		}),
		uploadFiles: builder.mutation({
			queryFn: (formData) => ({
				url: API_ROOT + basePath + '/upload',
				method: 'POST',
				body: formData,
			}),
		}),
		uploadImage: builder.mutation({
			query: (formData) => ({
				url: API_ROOT + basePath + '/upload-image',
				method: 'POST',
				body: formData,
			}),
		}),
	}),
});

const { useFilesQuery, useUploadFilesMutation, useUploadImageMutation } = fileApiSlice;

export { useFilesQuery, useUploadFilesMutation, useUploadImageMutation };
