import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { I18nContext } from 'src/_metronic/i18n/Metronici18n';
import { BUSINESS_LANGUAGES } from 'src/common/constants';
import Divider from 'src/components/utils/Divider';
import { logOut } from 'src/reducers/auth/authSlice';

import { setShowModal } from '../../../../../reducers/modalSlice';
import { MenuButton } from './MenuButton';

export function MenuInner() {
	const intl = useIntl();
	const dispatch = useDispatch();
	let langContext = useContext(I18nContext);

	const [currentLang, setCurrentLang] = useState('en');

	const changeLanguage = (langCode) => {
		setCurrentLang(langCode);
		langContext.changeLanguage(langCode);
	};

	const otherLanguages = BUSINESS_LANGUAGES.filter((langCode) => langCode.code !== currentLang);

	return (
		<>
			<MenuButton
				title={intl.formatMessage({ id: 'HEADER.MENU.BLOCKING' })}
				classname="py-3 btn white-button fw-bold"
				onClick={() => dispatch(setShowModal({ type: 'blocking' }))}
			/>

			<MenuButton
				title={intl.formatMessage({ id: 'HEADER.MENU.NEW_RESERVATION' })}
				classname="btn btn-primary"
				onClick={() => dispatch(setShowModal({ type: 'reservation_new' }))}
			/>
			<div className="d-sm-none">
				<Divider />
			</div>

			<div className="d-flex d-sm-none mx-3 cursor-pointer gap-3">
				<div className="d-flex align-items-center fw-bold fs-6 disabled-element">
					<img
						className="me-2 rounded-circle"
						src={`/media/flags/${currentLang === 'en' ? 'united-states' : 'greece'}.svg`}
						alt={`${currentLang === 'en' ? 'US' : 'Greek'} Flag`}
						aria-hidden="true"
						width="24"
						height="24"
					/>
					<div className="d-flex align-items-center ms-2 fw-bold fs-6">{currentLang === 'en' ? 'English' : 'Greek'}</div>
				</div>

				{otherLanguages.map((lang) => (
					<div key={lang.code} className="d-flex" onClick={() => changeLanguage(lang.code)}>
						<img className="me-2 rounded-circle" src={lang.flag} alt={`${lang.name} Flag`} aria-hidden="true" width="24" height="24" />
						<div className="d-flex align-items-center fw-bold fs-6">{lang.name}</div>
					</div>
				))}
			</div>

			<Divider />

			<div className="d-flex flex-column d-sm-none">
				<div className="menu-item mx-3">
					<a href="/settings/account/settings" className="menu-link">
						<FormattedMessage id="HEADER.MENU.USER.ACCOUNT" />
					</a>
				</div>
				<div className="menu-item mx-3">
					<a onClick={() => dispatch(logOut())} className="menu-link">
						<FormattedMessage id="HEADER.MENU.USER.SIGN_OUT" />
					</a>
				</div>
			</div>
		</>
	);
}
