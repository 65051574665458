import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormat, formatDate } from 'src/helpers/dates';
import * as Yup from 'yup';

import useModal from '../../../components/modal/useModal';
import { selectActiveBusiness, selectActiveStore } from '../../../reducers/business/businessSlice';
import { setShowModal } from '../../../reducers/modalSlice';
import { useCreateReservationMutation, useEditReservationMutation, useGetReservationQuery } from '../../../reducers/reservation/reservationApiSlice';
import { useGetStoreDetailsQuery } from '../../../reducers/stores/storeApiSlice';
import { sourceExtraOptions, statusExtraOptions } from '../reservationPages/ResOptions';
import TabDetails from '../reservationPages/TabDetails';
import TabPayments from '../reservationPages/TabPayments';

const ReservationModalBody = () => {
	const [createReservation] = useCreateReservationMutation();
	const [editReservation] = useEditReservationMutation();
	const dispatch = useDispatch();
	const { id, ref, editMode } = useModal();
	const { _id: activeStoreId } = useSelector(selectActiveStore);
	const { _id: business_id } = useSelector(selectActiveBusiness);
	const { data: reservation, isLoading: isLoadingReservation } = useGetReservationQuery({ path: { id: id } }, { skip: !editMode });
	const { data: store, isLoading: isLoadingStore } = useGetStoreDetailsQuery({
		bid: business_id,
		path: { id: reservation?.item?.store?._id ?? activeStoreId },
	});
	const validationSchema = Yup.object().shape({
		host: Yup.object().shape({
			fname: Yup.string().required('First name is required'),
			lname: Yup.string().required('Last name is required'),
			phone: Yup.string()
				.required('Phone number is required')
				.test('host.phone', 'Phone Number must be 10 digits', (value) => {
					if (!value) return true;
					return /^\d{10}$/.test(value);
				}),
			email: Yup.string().required('Email is required').email('Enter a valid email'),
		}),
		time: Yup.string().required('Please select an available time slot'),
	});
	const getPreferences = () =>
		reservation && editMode
			? reservation?.item?.preferences.map((item) => ({
					value: item?._id,
					label: item?.title,
			  })) ?? []
			: [];

	const getArea = () => [{ id: reservation?.item?.area?._id, value: reservation?.item?.area?._id, label: reservation?.item?.area?.title }];
	const getTime = (time) => formatDate(time, DateFormat.TIME).toString();
	let initialValues = {};
	useEffect(() => {
		if (!isLoadingReservation) {
			initialValues = {
				country_code: '+30',
				guest_country_code: '+30',
				store_id: reservation?.item?.store?._id ?? activeStoreId ?? store?._id,
				date: editMode ? reservation?.item?.date : new Date(),
				date_utc: editMode ? reservation?.item?.date_utc : new Date().getUTCDate(),
				time: editMode ? getTime(reservation?.item?.date) : undefined,
				people: editMode ? reservation?.item?.people ?? 2 : 2,
				area: editMode ? getArea() ?? [] : [],
				area_id: editMode ? reservation?.item?.area?._id ?? '' : '',
				table_ids: editMode ? reservation?.item?.table_ids ?? [] : [],
				team_notes: editMode ? reservation?.item?.team_notes ?? '' : '',
				message_to_guest: editMode ? reservation?.item?.message_to_guest ?? '' : '',
				duration_mins: editMode ? reservation?.item?.duration_mins ?? 120 : 120,
				preferences: editMode ? getPreferences() ?? [] : [],
				source: editMode ? reservation?.item?.source ?? sourceExtraOptions[1].value : sourceExtraOptions[1].value,
				status: editMode ? reservation?.item?.status ?? statusExtraOptions[0].value : statusExtraOptions[0].value,
				host: {
					_id: editMode ? reservation?.item?.host?._id ?? undefined : undefined,
					fname: editMode ? reservation?.item?.host?.fname ?? '' : '',
					lname: editMode ? reservation?.item?.host?.lname ?? '' : '',
					email: editMode ? reservation?.item?.host?.email ?? '' : '',
					phone: editMode ? reservation?.item?.host?.phone.slice(-10) ?? '' : '',
				},
				guests: [],
				guest_users: editMode ? reservation?.item?.guest_users ?? [] : [],
				guest_new_contact: [],
				tables: editMode ? reservation?.item?.tables ?? [] : [],
				payments: editMode ? reservation?.item?.payments ?? [] : [],
				prepayment: editMode ? reservation?.item?.prepayment ?? {} : [],
			};
		}
	}, [isLoadingReservation]);

	const {
		handleSubmit,
		register,
		watch,
		reset,
		resetField,
		control,
		trigger,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onSubmit',
		defaultValues: { ...initialValues },
		resolver: yupResolver(validationSchema),
		shouldFocusError: true,
	});

	useEffect(() => {
		reset({ ...initialValues });
	}, [reservation, reset]);

	/** Watch change of each entry **/
	// console.log(watch({ ...initialValues }));
	/** Show errors captured by validation **/
	// console.log(errors ? errors : 'Store information submitted successfully');

	useImperativeHandle(ref, () => ({ onSubmit: () => handleSubmit(onSubmit)() }));
	const isEmpty = (data) => {
		return typeof data === 'object'
			? Object.keys(data).length === 0 || Object.values(data).some((element) => element === undefined)
			: Array.isArray(data) && data.length === 0 && typeof data !== 'undefined';
	};

	const closeModal = () => {
		dispatch(setShowModal({ type: 'reservation', show: false }));
	};

	const onSubmit = async (data) => {
		const result = { ...data };
		const preferences = [];
		const guests = [];

		/** Formatting / Assignment **/
		result.date = `${formatDate(data.date, DateFormat.DAY_MONTH_YEAR_HYPHEN)} ${result.time}`;

		if (result.preferences && !isEmpty(result.preferences)) {
			result.preferences.forEach((item) => preferences.push(item.value));
			result.preferences = preferences;
		} else delete result.preferences;

		if (result.host) {
			if (!result?.host?._id) {
				result.host = { ...result.host, type: 'new_contact' };
				result.host.phone = `${result.country_code}${result.host.phone}`;
			} else {
				result.host = { ...result.host, type: 'contact' };
				result.host.id = result.host._id;
				result.host.phone = `${result.country_code}${result.host.phone}`;
				delete result.host._id;
			}
		}

		if (result.guest_users) {
			result.guest_users = result.guest_users.map((guest) => ({ ...guest, id: guest?.id, type: guest?.id ? 'contact' : 'tabol_user' }));
		}

		if (result?.guest_new_contact) {
			result?.guest_new_contact.forEach((guest) => {
				if (guest.type === 'new_contact') {
					guests.push({
						type: guest.type,
						fname: guest.firstname,
						lname: guest.lastname,
						email: guest.email,
						phone: `${result.guest_country_code}${guest.phoneNumber}`,
					});
				} else {
					guests.push(...result.guest_users, {
						type: guest.type,
						fname: guest.firstname,
						lname: guest.lastname,
						email: guest.email,
						phone: `${result.guest_country_code}${guest.phoneNumber}`,
						id: guest.id,
					});
				}
			});
		}
		result.guests = guests;

		/** Delete  **/
		// Empty checks
		const emptyCheckKeys = ['guests', 'prepayment', 'payment_ids', 'payments', 'table_ids'];
		emptyCheckKeys.forEach((key) => {
			if (isEmpty(result[key])) {
				delete result[key];
			}
		});

		// Empty conditional checks
		if (result.host?.tabol_user_id === '') {
			delete result.host.tabol_user_id;
		}

		if (result.area_id === '') {
			delete result.area_id;
		}

		// Targeted keys
		const keysToDelete = [
			'guest_id',
			'time',
			'date_utc',
			'hours',
			'stores',
			'area',
			'selectedPhoneNumberOption',
			'country_code',
			'guest_country_code',
			'guest_type',
			'guest_new_contact',
			'guest_users',
			'tables',
		];
		keysToDelete.forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(result, key)) {
				delete result[key];
			}
		});

		/** Submission **/
		if (Object.keys(errors).length === 0) {
			editMode
				? await editReservation({ path: { id: id }, body: { ...result } })
				: await createReservation({ path: { id: id }, body: { ...result } });
			closeModal();
		}
	};

	return (
		<>
			<ul className="nav nav-tabs nav-line-tabs d-flex justify-content-center mb-5 fs-3">
				<li className="nav-item">
					<a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_details" name="details">
						<FormattedMessage id="RESERVATIONS.MODAL_BODY.DETAILS" />
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" data-bs-toggle="tab" href="#kt_tab_payments" name="payments">
						<FormattedMessage id="RESERVATIONS.MODAL_BODY.PAYMENTS" />
					</a>
				</li>
			</ul>
			{!isLoadingReservation && !isLoadingStore ? (
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade active show" id="kt_tab_details" role="tabpanel">
						<TabDetails
							register={register}
							control={control}
							watch={watch}
							errors={errors}
							trigger={trigger}
							setValue={setValue}
							reset={reset}
							resetField={resetField}
							store={store}
							isStoreLoading={isLoadingStore}
						/>
					</div>

					<div className="tab-pane fade" id="kt_tab_payments" role="tabpanel">
						<TabPayments
							items={reservation}
							register={register}
							control={control}
							watch={watch}
							errors={errors}
							trigger={trigger}
							setValue={setValue}
							reset={reset}
						/>
					</div>
				</div>
			) : (
				<div className="d-flex justify-content-center">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			)}
		</>
	);
};

export default ReservationModalBody;
