import { SelectOption } from 'src/components/input/Select';

export const generateBlockingHours = (): SelectOption[] => {
	const hours: SelectOption[] = Array.from({ length: 24 }, (_, i) => {
		const hour = (i + 1).toString().padStart(2, '0') + ':00';
		return { value: hour, label: hour };
	});

	return hours;
};
