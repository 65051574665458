import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	areas: [],
};

const areasSlice = createSlice({
	name: 'service',
	initialState,
	reducers: {
		setAreasState: (state, action) => {
			state.areas = action.payload ?? [];
		},
	},
});

export const { setAreasState } = areasSlice.actions;
export const selectAreas = (state) => state.service.areas;
export default areasSlice.reducer;
