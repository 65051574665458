import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useTableWithItems from 'src/components/table/useTableWithItems';
import { v4 as uuidv4 } from 'uuid';

import LocationBookingGuestsDropdown from './openingHoursComponents/LocationBookingGuestsDropdowns';
import LocationDayHoursDropdowns from './openingHoursComponents/LocationDayHoursDropdowns';
import { dayOptions } from './openingHoursComponents/openingHoursOptions';
import OpeningHoursTable from './openingHoursComponents/OpeningHoursTable';

const TabOpeningHours = ({ control, register, trigger, watch, errors, setValue, resetField }) => {
	let data = {};
	const openingHoursData = watch('opening_hours') ?? [];
	const tempOptions = watch('opening_hours_temp') ?? {};
	const [editingHour, setEditingHour] = useState({});
	const [isEditMode, setEditMode] = useState(false);
	const [day, setDay] = useState(dayOptions[0].value);

	const handleEditHour = (id) => {
		const options = data?.items ?? selectedOptions?.options;
		const dayToEdit = options.find((hour) => hour._id === id);
		console.log(id);
		setShowInputFields(false);
		resetField('opening_hours_temp');
		setDay(dayToEdit?.day.toLowerCase().slice(0, 3) ?? '');
		setValue(`opening_hours_temp[${dayToEdit?.day.toLowerCase().slice(0, 3) ?? ''}]`, { ...dayToEdit });
		setEditingHour(dayToEdit);
		setEditMode(true);
		setTimeout(() => {
			setShowInputFields(true);
		}, 100);
	};

	const handleDeleteHour = (id) => {
		for (let key in openingHoursData) {
			if (openingHoursData[key]._id === id) {
				delete openingHoursData[key];
				trigger(['opening_hours']);
				setShowInputFields(false);
			}
		}
	};

	const [showInputFields, setShowInputFields] = useState(false);

	const [selectedOptions, setSelectedOptions] = useState({
		options: [],
		count: 0,
	});

	const updateSelectedOptions = () => {
		if (Object.keys(editingHour).length > 0 && isEditMode) {
			setSelectedOptions((prevOptions) => {
				const updatedOptions = prevOptions.options.map((hour) => (hour.id === editingHour.id ? { ...hour, ...openingHoursData[day] } : hour));
				setValue(`opening_hours[${day}]`, { ...tempOptions[day], time: `${tempOptions[day].start_time} - ${tempOptions[day].end_time}` });
				return {
					options: updatedOptions,
					count: updatedOptions.length,
				};
			});
			setEditingHour({});
		} else {
			setSelectedOptions((prevOptions) => {
				const newEntry = {
					_id: uuidv4(),
					...tempOptions[day],
					time: `${tempOptions[day].start_time} - ${tempOptions[day].end_time}`,
				};

				const updatedOptions = [...prevOptions.options, newEntry];
				setValue(`opening_hours[${day}]`, { ...newEntry });
				return {
					options: updatedOptions,
					count: updatedOptions.length,
				};
			});
		}
		setShowInputFields(false);
		resetField('opening_hours_temp');
		setDay(dayOptions[0].value);
	};

	const handleAddOpeningHourButton = () => {
		setEditMode(false);
		setTimeout(() => {
			setShowInputFields(true);
		}, 100);
	};

	const handleCancelButton = () => {
		setEditMode(false);
		setShowInputFields(false);
		setEditingHour({});
		resetField('opening_hours_temp');
	};

	data = {
		items: openingHoursData ? Object.values(openingHoursData) : 0,
		count: openingHoursData ? openingHoursData.length : 0,
	};

	const intl = useIntl();
	const tableValues = {
		title: intl.formatMessage({ id: 'STORE.TABLE' }) ?? 'Opening Hours',
		items: data?.items ? data?.items : selectedOptions.options,
		totalRows: data?.count ? data?.count : selectedOptions.count,
		content: <OpeningHoursTable onEditHour={handleEditHour} onDeleteHour={handleDeleteHour} />,
	};

	const { table } = useTableWithItems(tableValues);

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1">
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							{showInputFields && !isEditMode ? (
								<FormattedMessage id="LOCATION.MODAL.CREATE.OPENING_HOURS.TITLE" />
							) : showInputFields && isEditMode ? (
								<FormattedMessage id="LOCATION.MODAL.EDIT.OPENING_HOURS.TITLE" />
							) : (
								<FormattedMessage id="STORE.MODAL.OPENING_HOURS" />
							)}
						</div>
					</div>
				</div>

				{!showInputFields ? table : null}
				{data.items.length !== 7 && !showInputFields ? (
					<div className="row">
						<div className="col-12 col-sm-4">
							<button
								type="button"
								className="generic-button fs-6 w-100 fw-bold"
								onClick={handleAddOpeningHourButton}
								disabled={showInputFields === true}>
								<FormattedMessage id="LOCATION.OPENING_HOURS_TAB.ADD_HOUR" />
							</button>
						</div>
						{errors?.opening_hours && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.opening_hours.message}</span>
								</div>
							</div>
						)}
					</div>
				) : null}

				{showInputFields ? (
					<>
						<div className="row pb-5">
							<div className="col-12">
								<LocationDayHoursDropdowns
									register={register}
									control={control}
									watch={watch}
									setValue={setValue}
									resetField={resetField}
									day={day}
									setGlobalDay={setDay}
								/>
								<LocationBookingGuestsDropdown register={register} control={control} watch={watch} day={day} />
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="d-flex justify-content-end">
									<button type="submit" className="generic-button fs-6 fw-bold mx-5" onClick={updateSelectedOptions}>
										<FormattedMessage id="GENERAL.ADD" />
									</button>

									<button type="button" onClick={handleCancelButton} className="delete-button fs-6 fw-bold">
										<FormattedMessage id="GENERAL.CANCEL" />
									</button>
								</div>
							</div>
						</div>
					</>
				) : null}
			</form>
		</div>
	);
};

export default TabOpeningHours;
