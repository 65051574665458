import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Modal as RBModal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { areModalFormsActive } from 'src/reducers/modalFormSlice';

import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import useConfirmationModal from './useConfirmationModal';
import useModal from './useModal';

const Modal = () => {
	const { maxWidth, title, onClose, border } = useModal();
	const modalsActive = useSelector(areModalFormsActive);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	useEffect(() => {
		if (confirmModalOpen == true) {
			confirmAction(() => {
				onClose();
			});
		} else {
			console.log('confirmModalOpen was closed');
		}
	}, [confirmModalOpen]);

	const [confirmAction, confirmationModal] = useConfirmationModal(() => {
		console.log('onCancel');
		setConfirmModalOpen(false);
	});

	return (
		<>
			{confirmationModal}

			<RBModal
				id={`kt_modal_${title}`}
				tabIndex={-1}
				onEscapeKeyDown={(e) => {
					e.preventDefault();
					setConfirmModalOpen(true);
					console.log('onEscapeKeyDown');
				}}
				aria-hidden
				dialogClassName={`modal-dialog modal-dialog-centered ${maxWidth}`}
				style={{ zIndex: confirmModalOpen ? 1000 : 1100 }}
				show
				onHide={() => {
					setConfirmModalOpen(true);
					console.log('onEscapeKeyDown');
				}}
				backdrop
			>
				<ModalHeader />
				<ModalBody border />
				<ModalFooter />
			</RBModal>
		</>
	);
};

export default Modal;
