import { KTSVG } from 'src/_metronic/helpers';
import { DateFormat, formatDate, formatTime } from 'src/helpers/dates';

import { BlockingAreas, BlockingHoursItem } from './schema';

type BlockingItemProps = {
	edited?: boolean;
	onEdit: (id) => void;
	onDelete: (id) => void;
	onSave: (BlockingHoursItem) => void;
} & BlockingHoursItem;

const BlockingItem = ({ id, date, startTime, endTime, tabol, walk_in, widget, edited, onDelete, onEdit, onSave }: BlockingItemProps) => {
	const blockedAreas: BlockingAreas[] = [];
	const blockingFlags = { tabol, walk_in, widget };

	for (const [key, value] of Object.entries(blockingFlags)) {
		if (value) {
			blockedAreas.push(BlockingAreas[key.toUpperCase()]);
		}
	}

	return (
		<div className="row pb-4">
			<div className="blocking-container d-flex p-4">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex flex-column">
						<div className="fw-bold fs-4">
							{formatDate(date, DateFormat.DAY_MONTH_YEAR_HYPHEN)}, {formatTime(startTime)} - {formatTime(endTime)}
						</div>
						<div className="fs-6">{Object.values(blockedAreas).join(', ')}</div>
					</div>
					<div className="d-flex">
						{edited ? (
							<button
								type="button"
								onClick={() => onSave(id)}
								className="btn btn-sm info-box-secondary w-100 d-flex justify-content-center align-items-center p-4 me-5"
							>
								<KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-primary svg-icon-1" />
							</button>
						) : (
							<button
								type="button"
								onClick={() => onEdit(id)}
								className="btn btn-sm info-box-secondary w-100 d-flex justify-content-center align-items-center p-4 me-5"
							>
								<KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-primary svg-icon-1" />
							</button>
						)}
						<button
							type="button"
							onClick={() => onDelete(id)}
							disabled={edited}
							className="btn btn-sm btn-light-danger btn-color-danger w-100 d-flex justify-content-center align-items-center"
						>
							<KTSVG className="svg-icon-1" path="/media/icons/duotune/general/gen027.svg" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default BlockingItem;
